import React from 'react'
import { graphql } from 'gatsby'
import 'twin.macro'

import Layout from '../components/layout'
import Img from 'gatsby-image'
import SEO from "../components/seo"


const Books = ({data, location}) => {
  const siteTitle = data.site.siteMetadata.title
  const books = data.books.edges

  return(
    <Layout location={location} title={siteTitle} logoImage={data.logoImage}>
    <SEO title="Our Course Materials HSK and Conversational Chinese" description="Learning materials for HSK Chinese Mandarin courses and conversational courses"/>
      <h1 tw="text-center">Our Course Materials</h1>
      {books.map(({node}) => {
       return (
        <div id="books" tw="lg:w-3/4  mx-auto">
          <h2 tw="text-center">{node.description}</h2>
          <Img fluid={node.fluid} alt={node.description} tw="rounded" />
        </div>
        )

        }
        )}
    </Layout>

  )

}

export default Books

export const data = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    logoImage:contentfulAsset(title: {eq: "logo"}) {
      fluid(quality: 100, maxWidth: 300) {
        ...GatsbyContentfulFluid
      }
    }
    books:allContentfulAsset(filter: {title: {regex: "/Books >/"}}) {
    edges {
      node {
        title
        description
        fluid{
          ...GatsbyContentfulFluid

        }
      }
    }
  }
  }
`
